<template>
  <main>
  	<div class="campagne_wrapper">
  		<div class="fullpage-wp">
      <div class="quiz" v-if="!result_quiz">
        <img :src="`/slides/slide${question_index+1 }.png`">
        <h2 class="question_title">{{ question.question }} </h2>
        <ul>
          <li v-for="(answer, index) in answers" :key="index">
            <label :for="`answer[${index}]`">
              <input type="radio" :value="`${answer.profils}`" v-on:click="clickValue(answer.profils)"
                :id="`answer[${index}]`" v-model="checkedAnswerRadio[question_index*10+index]">
              <span v-html="answer.answer"></span>
            </label>
          </li>
        </ul>

      </div>
    </div>
    </div>
  </main>
</template>
<script>
import quiz_json from '../json/quiz.js'

export default {
  name: 'home',
  data() {
    return {
      master : [1,5,7,4,3,6,2],
      checkedAnswerRadio: [],
      checkedAnswer: [],
      question_index: 0,
      quiz: quiz_json,
      question: {},
      answers: undefined,
      points: 0,
      result_quiz: false,
      total : [{}],
      percent : [],
      status : undefined,
      principal : undefined,
      secondary : undefined,
      profils: {
        A:0,
        B:0,
        C:0,
        D:0,
        E:0,
        F:0,
        G:0
      }
    }
  },
  mounted() {
    this.quiz = quiz_json[0]['quiz']
    this.current_question()
    let agent = navigator.userAgent;
    if(agent.indexOf("Windows") > 0 || agent.indexOf("SamsungBrowser") > 0 || agent.indexOf("Android") > 0 || agent.indexOf("iPad") > 0 || window.innerWidth < 1200) document.getElementsByClassName("quiz")[0].classList.add("custom-browser")
  },
  methods: {
    controle_answsers: function () {
      if (document.querySelectorAll('input[type="radio"]:checked').length == 0) {
        return false
      }
      return true
    },
    clickValue(key) {
      var datas = key.split('|')
      this.profils[datas[0]]++;
      if(datas[1]) {
        this.profils[datas[1]]++;
      }
      this.sum_of_points();
      if(gtag) {
        gtag('event', 'answer_question', {
          'event_category': 'quiz',
          'event_label': `question_${this.question_index+1}`,
        });
      }
    },
    quiz_result: function () {
      if (!this.controle_answsers()) {
        alert('Vous devez répondre a cette question')
        return false
      } 
      this.result_quiz = true
    },
    next_question: function () {
      if (!this.controle_answsers()) {
        alert('Vous devez répondre a cette question')
        return false
      }
      this.question_index++;
      if(this.question_index == 6) { // fin du quizz
        localStorage.setItem('principal', this.principal);
        localStorage.setItem('ascendant', this.secondary);
        console.log({principal:this.principal, secondary: this.secondary})
        this.$router.push("/calcul");
      }
      if (this.question_index > this.quiz.length - 1) this.question_index = this.quiz.length - 1
      this.current_question()
    },
    prev_question: function () {
      if (!this.controle_answsers()) {
        alert('Vous devez répondre a cette question')
        return false
      }
      this.question_index--;
      if (this.question_index < 0) this.question_index = 0
      this.current_question()
    },
    current_question: function () {
      this.question = this.quiz[this.question_index]
      this.answers = this.question.answers
    },
    sum_of_points: function () {
      console.log("profils", this.profils);
      let total = []
      total.push({ profile : 'A', total : this.profils['A'], type :'gastronomaniac' })
      total.push({ profile : 'B', total : this.profils['B'], type :'aperitivore'  })
      total.push({ profile : 'C', total : this.profils['C'], type :'veggie-gourmand'  })
      total.push({ profile : 'D', total : this.profils['D'], type :'viandard' })
      total.push({ profile : 'E', total : this.profils['E'], type :'bec-sucre' })
      total.push({ profile : 'F', total : this.profils['F'], type :'street-lover' })
      total.push({ profile : 'G', total : this.profils['G'], type :'traditionista' })

      let tab = total
      total.sort((a, b) => parseFloat(b.total) - parseFloat(a.total));
      console.log("total", total);

      var master_arr = [] // ont stock tous les premiers identiques
      for (var i = 0, l = total.length; i < l; i++) {
         if(total[0].total == total[i].total) {
             master_arr.push(total[i].profile)
         }
      }       

      if(master_arr.length > 1) { // Master identiques détéctés
         this.status = 'Premiers identiques'
         var master = { C:1, B:2, E:3, D:4, F:5, G:6, A:7 }
         master_arr.sort(function(x, y){ return master[x] - master[y]; });
      }
      console.log('Master Map =  { C:1, B:2, E:3, D:4, F:5, G:6, A:7 }')
      console.log('Master = '+master_arr[0] )
      if(master_arr[0] == 'A') var map = { E:1, G:2, D:3, C:4, F:5, B:6 }
      if(master_arr[0] == 'B') var map = { F:1, D:2, G:3, E:4, A:5, C:6 }
      if(master_arr[0] == 'C') var map = { A:1, E:2, F:3, B:4, G:5, D:6 }
      if(master_arr[0] == 'D') var map = { G:1, B:2, F:3, A:4, E:5, C:6 }
      if(master_arr[0] == 'E') var map = { A:1, F:2, C:3, G:4, D:5, B:6 }
      if(master_arr[0] == 'F') var map = { B:1, D:2, C:3, E:4, G:5, A:6 }
      if(master_arr[0] == 'G') var map = { E:1, D:2, B:3, A:4, C:5, F:6 }
      console.log('Secondary Map = ', map)
      
      var secondaryTotal = total.filter(function (el) {
        return el.profile != master_arr[0];
      });

      if(secondaryTotal.length >0) {
         var secondary_arr= [] // ont stock tous les seconds identiques
         for (var i = 0, l = secondaryTotal.length; i < l; i++) {
            if(secondaryTotal[0].total == secondaryTotal[i].total) {
                secondary_arr.push(secondaryTotal[i].profile)
            }
         }
      }

      if(secondary_arr > 0) { // Seconds identiques détéctés
         this.status = 'Seconds identiques'
         secondary_arr.sort(function(x, y){ return map[x] - map[y]; });
      } 

      console.log('Second = '+secondary_arr[0] );

      var result1 =tab.filter(function(obj) {   return obj.profile == master_arr[0];  })
      var result2 =tab.filter(function(obj) {   return obj.profile == secondary_arr[0];  })

      this.principal = result1[0].type 
      this.secondary = result2[0].type
      this.total = total;

      this.next_question();
    },
    clearlopp() {}
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";

.quiz_wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url('../assets/images/fond-18.jpg');
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.quiz {
  width: 100%;
  text-align: center;
  padding-top: 20px;

  img {
    width: 541px;
    margin-bottom: 20px;
  }

  .question_title {
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;
    color: white;
    font-size: 48px;
    line-height: 58px;
    width: 500px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    min-height: 116px;

    @media screen and (min-width: $mobile-width) and (max-height: 685px) {
      margin-bottom: 20px !important;
      font-size: 40px;


    }
      @media screen and (max-width: $mobile-width) {
         min-height: unset !important;
      }
  }

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    text-align: center;
    // padding-top: 110px;
      min-height: unset !important;

    img {
      width: 93%;
      margin-bottom: 30px;
      margin: 0 auto;
    }

    .question_title {
      width: 80%;
      font-size: 38px;
      line-height: 38px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 20px;

      @media screen and (max-width: $mobile-width) {
             width: 98%;
        margin-top: 10px;
        font-size: 35px;
      line-height: 35px;      
      }
    }
  }
}

p {
  font-size: 18px;
}

a {
  cursor: pointer;
}

 
.custom-browser ul li label:hover {
  @media screen and (min-width: $mobile-width) {
   background-color: rgba(0,0,0,.5);
   color: #fff;
  }
}

ul {
  width: 100%;
  text-align: center;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    width: 450px;
    margin: 0 auto;
  }

  li label {
    font-family: "acumin-pro", sans-serif;
    font-weight: 500;    
    position: relative;
    display: block;
    //width: 89%;
    font-size: 21px;
    line-height: 21px;
    cursor: pointer;
    border-radius: 60px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 22px 13px;
    margin-bottom: 20px;



    @media screen and (min-width: $mobile-width) and (max-height: 685px) {
      margin-bottom: 10px;
      font-size: 19px;
      line-height: 19px;
      padding: 19px 11px;
    }

      @media screen and (max-width: $mobile-width) {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 16px;
      padding: 15px 8px;
      padding-top: 10px;
      padding-bottom: 15px;
      }

    initial-letter: 2;

    input {
      position: relative;
      visibility: hidden;
      display: none;
      background: transparent;
    }
  }

  li label::first-letter {
    text-transform: capitalize;
  }

  li label:hover {
    @media screen and (min-width: $mobile-width) {
      background-color: white;
      color: black;
    }
  }

  ::marker {
    display: none;
  }

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    margin-bottom: 50px;

    li label {
      width: 78%;
      font-size: 20px;
      line-height: 21px;
    }

    li {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.result {
  width: 550px;
  margin: 0 auto;

  p {
    width: 100%;
  }
}
</style>
